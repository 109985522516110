<template>
  <div class="bg-wrapper">
    <div class="background-layer-1"></div>
    <div class="background-layer-2"></div>
    <div class="background-layer-3"></div>
    <div class="background-divider"></div>
  </div>
</template>

<script>
export default {
  name: "BackgroundGradient",
};
</script>

<style lang="scss" scoped>
.bg-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.background-layer-1 {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #02468d 30%, #60a2ec);
  z-index: 1;
}

.background-layer-2 {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(60deg, transparent 40%, #ff7052);
  z-index: 2;
  //mix-blend-mode: screen; /* Tuo kevyen kerroksen näkyviin */
}

.background-layer-3 {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.2));
  z-index: 3;
}

.background-divider {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #091a46 0%, #012f73 25%, #3c4469 42%);
  clip-path: polygon(0 0, 35% 0, 27% 100%, 0% 100%);
  z-index: 4;
}
@media screen and (max-width: 1024px) {
  .background-divider {
    display: none;
  }
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import apiCollection from "./api/api.js";
import i18n from "./i18n.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    mainMenuOpen: false,
    notificationObject: {
      color: "alert",
      message: "",
      timeOut: 4000,
      showButton: false,
      buttonText: "",
      callback: () => {},
    },
    loadingOverlay: false,
    bottomNavigation: false,
    entries: [],
    sites: [],
    equipments: [],
    questionnaireTypes: ["360", "CDD", "Motivation", "Personality", "Board", "Other"],
    selectedQuestionnaireType: null,
    testers: [
      /*'@hubble.fi',*/
      /*'anssi.saxelin@humandimension.fi', '@signium.se'*/
    ],
    areaColors: {
      "DO RIGHT": {
        header: "#003789",
        cell: "#4c73ac",
        color: "blue",
        shades: { E: "#1a4b94", D: "#2d61af", C: "#3f77cb", B: "#6597e1", A: "#a2c0ed" },
      },
      "DO FIRST": {
        header: "#e31f40",
        cell: "#d27373",
        color: "red",
        shades: { E: "#f62727", D: "#f15656", C: "#f66868", B: "#f48686", A: "#faa1a1" },
      },
      "DO MOST": {
        header: "#f1ad4a",
        cell: "#fff796",
        color: "yellow",
        shades: { E: "#ffcc00", D: "#ffd940", C: "#ffe57a", B: "#ffea98", A: "#ffefb2" },
      },
      "DO BEST": {
        header: "#449618",
        cell: "#7cb55d",
        color: "green",
        shades: { E: "#17745d", D: "#1e9b7e", C: "#64ab9a", B: "#83c6b5", A: "#a1ddce" },
      },
      "DO WISELY": {
        header: "#f16c33",
        cell: "#ffb74c",
        color: "orange",
        shades: { E: "#ff741e", D: "#ff8e48", C: "#ffa268", B: "#ffab77", A: "#fdb78c" },
      },

      "GÖR RÄTT": {
        header: "#003789",
        cell: "#4c73ac",
        color: "blue",
        shades: { E: "#1a4b94", D: "#2d61af", C: "#3f77cb", B: "#6597e1", A: "#a2c0ed" },
      },
      "GÖR FÖRST": {
        header: "#e31f40",
        cell: "#d27373",
        color: "red",
        shades: { E: "#f62727", D: "#f15656", C: "#f66868", B: "#f48686", A: "#faa1a1" },
      },
      "GÖR MEST": {
        header: "#f1ad4a",
        cell: "#fff796",
        color: "yellow",
        shades: { E: "#ffcc00", D: "#ffd940", C: "#ffe57a", B: "#ffea98", A: "#ffefb2" },
      },
      "GÖR BÄST": {
        header: "#449618",
        cell: "#7cb55d",
        color: "green",
        shades: { E: "#17745d", D: "#1e9b7e", C: "#64ab9a", B: "#83c6b5", A: "#a1ddce" },
      },
      "GÖR KLOKT": {
        header: "#f16c33",
        cell: "#ffb74c",
        color: "orange",
        shades: { E: "#ff741e", D: "#ff8e48", C: "#ffa268", B: "#ffab77", A: "#fdb78c" },
      },

      "PURPOSE-DRIVEN": {
        header: "#3b8e80",
        cell: "#9ec5bf",
        color: "green",
        shades: { E: "#17745d", D: "#1e9b7e", C: "#64ab9a", B: "#83c6b5", A: "#a1ddce" },
      },
      "PERFORMANCE-DRIVEN": {
        header: "#e31f40",
        cell: "#ef97a8",
        color: "red",
        shades: { E: "#f62727", D: "#f15656", C: "#f66868", B: "#f48686", A: "#faa1a1" },
      },
      "ENGAGEMENT-DRIVEN": {
        header: "#f1ad4a",
        cell: "#f8c799",
        color: "yellow",
        shades: { E: "#ffcc00", D: "#ffd940", C: "#ffe57a", B: "#ffea98", A: "#ffefb2" },
      },
      "PROCESS-DRIVEN": {
        header: "#2856ad",
        cell: "#7693c7",
        color: "blue",
        shades: { E: "#1a4b94", D: "#2d61af", C: "#3f77cb", B: "#6597e1", A: "#a2c0ed" },
      },
      "EXTERNALLY DRIVEN": {
        header: "#f16c33",
        cell: "#fcb8aa",
        color: "orange",
        shades: { E: "#ff741e", D: "#ff8e48", C: "#ffa268", B: "#ffab77", A: "#fdb78c" },
      },

      ["Purpose-driven leader".toUpperCase()]: {
        header: "#3b8e80",
        cell: "#9ec5bf",
        color: "green",
        shades: { E: "#17745d", D: "#1e9b7e", C: "#64ab9a", B: "#83c6b5", A: "#a1ddce" },
      },
      ["Performance dimension".toUpperCase()]: {
        header: "#e31f40",
        cell: "#ef97a8",
        color: "red",
        shades: { E: "#f62727", D: "#f15656", C: "#f66868", B: "#f48686", A: "#faa1a1" },
      },
      ["Engagement-driven leader".toUpperCase()]: {
        header: "#f1ad4a",
        cell: "#f8c799",
        color: "yellow",
        shades: { E: "#ffcc00", D: "#ffd940", C: "#ffe57a", B: "#ffea98", A: "#ffefb2" },
      },
      ["Process-driven leader".toUpperCase()]: {
        header: "#2856ad",
        cell: "#7693c7",
        color: "blue",
        shades: { E: "#1a4b94", D: "#2d61af", C: "#3f77cb", B: "#6597e1", A: "#a2c0ed" },
      },
      ["External-driven leader".toUpperCase()]: {
        header: "#f16c33",
        cell: "#fcb8aa",
        color: "orange",
        shades: { E: "#ff741e", D: "#ff8e48", C: "#ffa268", B: "#ffab77", A: "#fdb78c" },
      },

      ["Purpose-driven".toUpperCase()]: {
        header: "#3b8e80",
        cell: "#9ec5bf",
        color: "green",
        shades: { E: "#17745d", D: "#1e9b7e", C: "#64ab9a", B: "#83c6b5", A: "#a1ddce" },
      },
      ["Performance-driven".toUpperCase()]: {
        header: "#e31f40",
        cell: "#ef97a8",
        color: "red",
        shades: { E: "#f62727", D: "#f15656", C: "#f66868", B: "#f48686", A: "#faa1a1" },
      },
      ["Engagement-driven".toUpperCase()]: {
        header: "#f1ad4a",
        cell: "#f8c799",
        color: "yellow",
        shades: { E: "#ffcc00", D: "#ffd940", C: "#ffe57a", B: "#ffea98", A: "#ffefb2" },
      },
      ["Process-driven".toUpperCase()]: {
        header: "#2856ad",
        cell: "#7693c7",
        color: "blue",
        shades: { E: "#1a4b94", D: "#2d61af", C: "#3f77cb", B: "#6597e1", A: "#a2c0ed" },
      },
      ["External-driven".toUpperCase()]: {
        header: "#f16c33",
        cell: "#fcb8aa",
        color: "orange",
        shades: { E: "#ff741e", D: "#ff8e48", C: "#ffa268", B: "#ffab77", A: "#fdb78c" },
      },
      ["Externally driven".toUpperCase()]: {
        header: "#f16c33",
        cell: "#fcb8aa",
        color: "orange",
        shades: { E: "#ff741e", D: "#ff8e48", C: "#ffa268", B: "#ffab77", A: "#fdb78c" },
      },
      ["Innovation-driven".toUpperCase()]: {
        header: "#3b8e80",
        cell: "#9ec5bf",
        color: "green",
        shades: { E: "#17745d", D: "#1e9b7e", C: "#64ab9a", B: "#83c6b5", A: "#a1ddce" },
      },
      ["Curiosity-driven".toUpperCase()]: {
        header: "#3b8e80",
        cell: "#9ec5bf",
        color: "green",
        shades: { E: "#17745d", D: "#1e9b7e", C: "#64ab9a", B: "#83c6b5", A: "#a1ddce" },
      },

      AGREEABLENESS: {
        header: "#3b8e80",
        cell: "#9ec5bf",
        color: "green",
        shades: { E: "#17745d", D: "#1e9b7e", C: "#64ab9a", B: "#83c6b5", A: "#a1ddce" },
      },
      CONCIENTIOUSNESS: {
        header: "#e31f40",
        cell: "#ef97a8",
        color: "red",
        shades: { E: "#f62727", D: "#f15656", C: "#f66868", B: "#f48686", A: "#faa1a1" },
      },
      EXTRAVERSION: {
        header: "#f1ad4a",
        cell: "#f8c799",
        color: "yellow",
        shades: { E: "#ffcc00", D: "#ffd940", C: "#ffe57a", B: "#ffea98", A: "#ffefb2" },
      },
      "EMOTIONAL STABILITY": {
        header: "#2856ad",
        cell: "#7693c7",
        color: "blue",
        shades: { E: "#1a4b94", D: "#2d61af", C: "#3f77cb", B: "#6597e1", A: "#a2c0ed" },
      },
      "OPENNESS TO CHANGE": {
        header: "#f16c33",
        cell: "#fcb8aa",
        color: "orange",
        shades: { E: "#ff741e", D: "#ff8e48", C: "#ffa268", B: "#ffab77", A: "#fdb78c" },
      },

      ["1. Ägardirektiv och Strategi".toUpperCase()]: {
        header: "#3b8e80",
        cell: "#9ec5bf",
        color: "green",
        shades: { E: "#17745d", D: "#1e9b7e", C: "#64ab9a", B: "#83c6b5", A: "#a1ddce" },
      },
      ["2. Bolagsstyrning".toUpperCase()]: {
        header: "#e42e4d",
        cell: "#ef97a8",
        color: "red",
        shades: { E: "#f62727", D: "#f15656", C: "#f66868", B: "#f48686", A: "#faa1a1" },
      },
      ["3. Styrelsen sammansättning och förutsättningar".toUpperCase()]: {
        header: "#f1ad4a",
        cell: "#f8c799",
        color: "yellow",
        shades: { E: "#ffcc00", D: "#ffd940", C: "#ffe57a", B: "#ffea98", A: "#ffefb2" },
      },
      ["4. Styrelsens löpande arbete".toUpperCase()]: {
        header: "#2856ad",
        cell: "#7693c7",
        color: "blue",
        shades: { E: "#1a4b94", D: "#2d61af", C: "#3f77cb", B: "#6597e1", A: "#a2c0ed" },
      },
      ["5. VD och Successionsplanering".toUpperCase()]: {
        header: "#f16c33",
        cell: "#fcb8aa",
        color: "orange",
        shades: { E: "#ff741e", D: "#ff8e48", C: "#ffa268", B: "#ffab77", A: "#fdb78c" },
      },

      NONE1: { header: "#d800f8", cell: "#e071fc" },
      NONE2: { header: "#00ffd0", cell: "#8affe6" },
      NONE3: { header: "#a9fc00", cell: "#d1ff84" },
      UNKNOWN: {
        header: "#003789",
        cell: "#4c73ac",
        color: "blue",
        shades: { E: "#1a4b94", D: "#2d61af", C: "#3f77cb", B: "#6597e1", A: "#a2c0ed" },
      },
    },
  },
  mutations: {
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    toggleMainMenuState(state) {
      state.mainMenuOpen = !state.mainMenuOpen;
    },
    setPreviousRouteName(state, val) {
      state.previousRouteName = val;
    },
    setLoadingOverlay(state, payload) {
      state.loadingOverlay = payload;
    },
    setEntries(state, payload) {
      state.entries = payload;
    },
    setSites(state, payload) {
      state.sites = payload;
    },
    setEquipment(state, payload) {
      state.equipments = payload;
    },
    setSelectedQuestionnaireType(state, payload) {
      state.selectedQuestionnaireType = payload;
    },
  },
  actions: {
    showNotification(context, payload) {
      context.state.notificationObject = {
        color: payload.color,
        message: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : "",
        callback: payload.showButton && payload.callback ? payload.callback : () => {},
      };
    },
    async reloadEntries(context) {
      try {
        const allEntries = await apiCollection.getAllEntries();
        allEntries.map(entry => {
          if (entry.equipments) {
            entry.equipmentIds = entry.equipments.map(e => e.id);
          } else {
            entry.equipmentIds = [];
          }
        });
        context.commit("setEntries", allEntries);
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_loading_entries"),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t("try_again"),
          callback: () => {
            context.dispatch("reloadEntries");
          },
        });
        console.log(error);
      }
    },
    async upsertEntry(context, payload) {
      try {
        const entry = await apiCollection.upsertEntry(payload);
        return entry;
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_updating_entry"),
          showButton: false,
        });
        console.log(error);
      }
      return null;
    },
    async reloadSites(context) {
      try {
        const allSites = await apiCollection.getAllSites();
        context.commit("setSites", allSites);
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_loading_sites"),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t("try_again"),
          callback: () => {
            context.dispatch("reloadSites");
          },
        });
        console.log(error);
      }
    },
    async upsertSite(context, payload) {
      try {
        const response = await apiCollection.upsertSite(payload);
        await context.dispatch("reloadSites");
        return response;
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_updating_site"),
          showButton: false,
        });
        console.log(error);
        return false;
      }
    },
    async reloadEquipments(context) {
      try {
        const allEquipment = await apiCollection.getAllEquipments();
        context.commit("setEquipment", allEquipment);
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_loading_equipment"),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t("try_again"),
          callback: () => {
            context.dispatch("reloadEquipment");
          },
        });
        console.log(error);
      }
    },
    async upsertEquipment(context, payload) {
      try {
        await apiCollection.upsertEquipment(payload);
        await context.dispatch("reloadEquipments");
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_updating_equipment"),
          showButton: false,
        });
        console.log(error);
        return false;
      }
      return true;
    },
    async massUpdateInvoicedStates(context, payload) {
      try {
        const response = await apiCollection.massUpdateInvoiced(payload);
        await context.dispatch("reloadEntries");
        return response;
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_mass_updating_invoiced_state"),
          showButton: false,
        });
        console.log(error);
        return false;
      }
    },
  },
});
